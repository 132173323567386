import React, { ReactElement } from 'react'
import { Helmet } from 'react-helmet'
import { withPrefix } from 'gatsby'
import useSiteMetadata from './siteMetadata'

const Seo = (): ReactElement => {
  const {
    title,
    description,
    language,
    image,
    siteUrl,
    author,
  } = useSiteMetadata()

  return <Helmet title={title}>
    <html lang={language} />
    <meta name="description" content={description} />
    <meta name="image" content={image} />
    <meta property="og:title" content={title} />
    <meta property="og:url" content={siteUrl} />
    <meta property="og:description" content={description} />
    <meta property="og:image" content={image} />
    <meta property="og:type" content="website" />
    <meta property="og:image:alt" content={description} />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content={title} />
    <meta name="twitter:url" content={siteUrl} />
    <meta name="twitter:description" content={description} />
    <meta name="twitter:image" content={image} />
    <meta name="twitter:image:alt" content={description} />
    <meta name="twitter:creator" content={author} />
    <link rel="icon" type="image/png" sizes="32x32" href={withPrefix(`/favicon-32x32.png`)} />
    <link rel="icon" type="image/png" sizes="16x16" href={withPrefix(`/favicon-16x16.png`)} />
    <link rel="apple-touch-icon" sizes="180x180" href={withPrefix(`/apple-touch-icon.png`)} />
  </Helmet>
}

export default Seo