import { graphql, useStaticQuery } from "gatsby"

const useSiteMetadata = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulCategory {
        nodes {
          title
          icon {
            file {
              url
            }
          }
        }
      }
    }
  `)
  return data.allContentfulCategory.nodes
}

export default useSiteMetadata
