import reset from 'styled-reset'
import { createGlobalStyle } from 'styled-components'

import colors from './colors'
import { device } from './breakPoints'

const globalStyle = createGlobalStyle`
  ${reset}

  @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

  html {
    box-sizing: border-box;
    height: 100%;
    font-size: 18px;
    background-color: ${colors.primary.dark};
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    font-family: 'Poppins', sans-serif;
    line-height: 150%;
    height: 100%;
    margin: 0;
    #___gatsby, #gatsby-focus-wrapper {
      height: 100%;
    }
  }

  h1, h2, h3 {
    font-family: 'Montserrat', sans-serif;
    color: ${colors.primary.light};
    font-weight: 600;
    line-height: 150%;
  }

  h1 {
    font-size: 3rem;
    padding: 1rem 0;
    text-align: center;

    @media ${device.lg} {
      text-align: left;
    }
  }
  h2 {
    font-size: 2rem;
  }
  h3, h4 {
    font-size: 1.5rem;
  }

  p, h4, h5, h6 {
    font-family: 'Poppins', sans-serif;
    line-height: 120%;
  }

  p, h4 {
    color: ${colors.primary.light};
  }

  h5, h6 {
    color: ${colors.grey.light};
  }

  a {
    text-decoration: none;
    :focus {
      outline: none;
    }
  }

  button {
    background: none;
    border: none;
  }

`

export default globalStyle
