import React, { ReactElement, ReactNode } from 'react'

import GlobalStyle from '../Styles/global';
import { Container } from './styles';
import Seo from '../components/Seo'
import Menu from '../components/Menu'

interface Props {
  children: ReactNode;
}

const Layout = ({ children }: Props): ReactElement => {

  return <Container>
    <GlobalStyle />
    <Seo />
    <Menu />
    {children}
  </Container>;
}

export default Layout;