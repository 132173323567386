
export const primary = {
  main: '#53448C',
  light: '#F7EBFF',
  mid: '#8874C2',
  dark: '#2A2646',
}

export const secondary = {
  main: '#a57a7a',
  light: '#FFE4E3',
  dark: '#7B6379',
}

export const grey = {
  light: '#AEA8BA',
  mid: '#7A7485',
  dark: '#444B50',
  black: '#010101',
  transparent: '#7A74858a',
  blackTransparent: '#0101018a',
}

export const gradient = {
  primary: `linear-gradient(to right, ${primary.mid} 0%, ${primary.dark} 100%)`,
  secondary: `linear-gradient(to right, ${secondary.main} 0%, ${secondary.dark} 100%)`,
  extra: 'linear-gradient(to right, SlateBlue 0%, DeepSkyBlue 100%)',
}

export default {
  primary,
  secondary,
  grey,
  gradient,
}
