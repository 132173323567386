import React, { ReactElement } from 'react'
import { Link } from 'gatsby'

import Resume from '../../Assets/svg/description_black_24dp.svg'
import Logo from '../../Assets/Logo.png'

import useCategories from './useCategories'
import {
  Container,
  BGFilter,
  Content,
  MenuContainer,
  DesktopMenu,
  HomeLogo,
} from './styles'
import Mobile from './Mobile'

const Menu = (): ReactElement => {
  const categories = useCategories()

  return <MenuContainer>
    {categories.map(
      ({ title, icon }) => <Link key={title} to={`/${title}`}>
        <img src={icon.file.url} alt="" />
        <p>{title}</p>
      </Link>
    )}
    <a
      href="https://drive.google.com/file/d/1cdgC985E7K7SgBMBRFzQz_gUV63AHV0n/view?usp=sharing"
      target="__blank"
    >
      <img src={Resume} alt="" /><p>Resume</p>
    </a>
  </MenuContainer>
}

const NavMenu = (): ReactElement => (
  <div>
    <Container>
      <BGFilter />
      <Content>
        <HomeLogo to={'/'}>
          <img src={Logo} alt='' />
          <h3>Josh Wentworth</h3>
        </HomeLogo>
        <Mobile>
          <Menu />
        </Mobile>
        <DesktopMenu>
          <Menu />
        </DesktopMenu>
      </Content>
    </Container>
  </div>
)

export default NavMenu
