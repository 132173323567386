import styled from 'styled-components'
import { Link } from 'gatsby'

import { device } from '../../Styles/breakPoints'
import { grey, primary } from '../../Styles/colors'

const blur = 'blur(10px)'
export const sizes = {
  width: '22rem',
  height: '6rem',
}

export const Container = styled.header`
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  display: flex;
  height: ${sizes.height};
  width: 100vw;
  flex-direction: row;
  justify-content: center;
  border-bottom: 1px solid ${grey.mid};

  @media ${device.lg} {
    border-bottom: none;
    width: ${sizes.width};
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;
  }
`

export const HomeLogo = styled(Link)`
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding: 2rem;
  img {
    margin-right: 1rem;
    height: 3rem;
    display: none;
    @media ${device.lg} {
      display: block;
    }
  }

`

export const Content = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  @media ${device.lg} {
    flex-direction: column;
    a {
      flex-grow: 0;
      width: 100%;
    }
  }
`

export const BGFilter = styled.div`
  width: 100%;
  height: 100%;
  backdrop-filter: ${blur};
`

export const MenuContainer = styled.nav`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  a {
    display: flex;
  }
  p {
    line-height: 200%;
    font-weight: 300;
    letter-spacing: 1px;
  }
  @media ${device.lg} {
    border-bottom: none;
  }
`

export const MobileMenu = styled.div`
  margin-right: 2rem;
  width: 100%;

  a {
    img {
      display: none;
    }
  }

  button {
    color: ${primary.light};
    svg {
      path:not(:first-child) {
        fill: currentColor;
      }
    }
  }

  nav {
    position: absolute;
    top: 100%;
    right: 0;
    backdrop-filter: ${blur};
  }

  @media ${device.lg} {
    display: none;
  }
`

export const DesktopMenu = styled.div`
  width: 100%;

  a {
    display: flex;
    align-items: center;
    margin: 0.3rem 0;
    img {
      width: 2rem;
      height: auto;
      margin-right: 1rem;
    }
  }

  display: none;
  @media ${device.lg} {
    display: block;
  }
`
