import React, { ReactElement, ReactNode, useState } from 'react'

import Icon from '../../Assets/Menu'
import { MobileMenu } from './styles'

interface Props {
  children: ReactNode;
}

const Mobile = ({ children }: Props): ReactElement => {
  const [ open, setOpen ] = useState(false)

  return <MobileMenu>
    <button onClick={() => setOpen(!open)}>
      <Icon width="3rem" height="auto" />
    </button>
    {open && children}
  </MobileMenu>
}

export default Mobile
